import { useContext } from 'react';
import { CartContext } from '../../../contexts/CartContext';
import Countdown from 'react-countdown';

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
	if (completed) {
		// Render a completed state
		// startmodelcartitem();
		// return <Completionist />;
		return null;
	} else {
		// Render a countdown
		// return (
		// 	<span style={{ color: 'red', fontSize: '15px' }}>
		// 		{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
		// 		{/* {hours}:{minutes}:{seconds} */}
		// 	</span>
		// );
		//
		return null;
	}
};

const CommonTimer = () => {
	const {
		itemsCount,
		itemsCountstart,
		setItemsCount,
		setItemsCountstart,
		cartitemcount,
		setCartitemcount,
		key,
		setKey,
	} = useContext(CartContext);
	return (
		<>{itemsCountstart && <Countdown date={itemsCountstart} renderer={renderer} key={key} />}</>
	);
};
export default CommonTimer;
