const LANG = {
	EN: {
		text: 'English',
		lng: 'en',
		icon: 'CustomUsa',
	},
	DE: {
		text: 'Deutsche',
		lng: 'de',
		icon: 'CustomGermany',
	},
	FR: {
		text: 'Français',
		lng: 'fr',
		icon: 'CustomFrance',
	},
	TR: {
		text: 'Türkçe',
		lng: 'tr',
		icon: 'CustomTurkey',
	},
	SI: {
		text: 'සිංහල',
		lng: 'si',
		icon: 'CustomSriLanka',
	},
};

export const getLangWithKey = (key) => {
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
