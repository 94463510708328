import React, { useState, useEffect } from 'react';
import apiServicewt from '../api/apiServicewt';

const Logo = () => {
	const [logo, setLogo] = useState([]);

	useEffect(() => {
		const fetchata = async () => {
			const item = { action: 'get_login_settings' };
			const flechdata = await apiServicewt(item);
			if (flechdata.type === 'Success' || flechdata.type === 'success') {
				setLogo(flechdata.data);
			}
		};
		fetchata();
	}, []);

	return (
		<img
			style={{ width: '160px', height: '40px', objectFit: 'contain', fontSize: '20px' }}
			src={logo.site_logo}
			alt='Site Logo'
		/>
	);
};
Logo.propTypes = {
	// width: PropTypes.number,
	// height: PropTypes.number,
};
Logo.defaultProps = {
	// width: 2155,
	// height: 854,
};

export default Logo;
