import * as React from 'react';

const SvgCustomSriLanka = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 512 512'
		style={{
			enableBackground: 'new 0 0 512 512',
			borderRadius: '20%',
		}}
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		{/* Background */}
		<rect width='512' height='512' rx='50' ry='50' fill='#ffb915' />

		{/* Green and Orange Stripes */}
		<rect width='102.4' height='512' rx='50' ry='50' fill='#007847' />
		<rect x='102.4' width='102.4' height='512' rx='50' ry='50' fill='#ff6319' />

		{/* Maroon Section */}
		<rect x='204.8' width='307.2' height='512' rx='50' ry='50' fill='#8d153a' />

		{/* Lion Figure (Simplified Representation) */}
		<path
			fill='#ffb915'
			d='M390 150c-10 20-40 40-40 40l20 10-30 10 10 10-20 10h30l-10 20 20 10h-30l20 10-10 10 20 10-10 10 20 10-10 10 10 10-10 10h-80l-10-10v-20l10-10h-10v-20h-10v-30h10l-10-10 10-10-10-10 10-10-10-10 10-10h40l20-20z'
		/>

		{/* Leaves in Corners */}
		<circle cx='230' cy='50' r='10' fill='#ffb915' />
		<circle cx='470' cy='50' r='10' fill='#ffb915' />
		<circle cx='230' cy='460' r='10' fill='#ffb915' />
		<circle cx='470' cy='460' r='10' fill='#ffb915' />
	</svg>
);

export default SvgCustomSriLanka;
