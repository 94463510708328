import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Rodal from 'rodal';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import '../../presentation/auth/Login.css';
import 'rodal/lib/rodal.css';
import { Ralert } from '../vhelper/Normarodallalert';
import Icon from '../../../components/icon/Icon';
import apiServicewt from '../../../api/apiServicewt';
import { useTranslation } from 'react-i18next';
import LANG, { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import { useEffect } from 'react';
import Popovers from '../../../components/bootstrap/Popovers';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	const { t } = useTranslation();

	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>{t('Create Account,')}</div>
				<div className='text-center h4 text-muted mb-5'>{t('Sign up to get started!')}</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h2 fw-bold mt-5'>{t('Forgot your password?')}</div>
			<br />
			<br />
			<div>
				{t(
					'Enter your email address below and we’ll send you a code to reset your password',
				)}
			</div>
			<br />
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { t } = useTranslation();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { i18n } = useTranslation();
	const [usernameInput, setUsernameInput] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [isempty2, setIsempty2] = useState(false);
	const [loadalert, setLoadalert] = useState(false);
	const [msgloadalert, setMsgLoadalert] = useState('');
	const [msgtypeloadalert, setMsgtypeLoadalert] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const closemodel = () => {
		setLoadalert(false);
	};
	const startmodel = () => {
		setLoadalert(true);
	};
	const closemodel2 = () => {
		setIsempty2(false);
	};
	const startmodel2 = () => {
		setIsempty2(true);
	};

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const u_class = '.gt_container--hich1m';

	useEffect(() => {
		const currentLang = getLangWithKey(i18n.language);

		if (!currentLang) {
			const langLink = document.querySelector(`.gt_option a[data-gt-lang="${LANG.EN.lng}"]`);

			if (langLink) {
				langLink.click();
				i18n.changeLanguage(LANG.EN.lng);
			}
		} else {
			const langLink = document.querySelector(
				`.gt_option a[data-gt-lang="${currentLang.lng}"]`,
			);

			if (langLink) {
				langLink.click();
				i18n.changeLanguage(currentLang.lng);
			}
		}
	}, []);

	const changeLanguage = (lang) => {
		document.querySelectorAll(u_class + ' .gt_switcher div.gt_selected').forEach(function (e) {
			e.addEventListener('click', function (evt) {
				evt.preventDefault();
				evt.stopPropagation();
				if (gt_slider_open) gt_hide_slider();
				else gt_show_slider(e.parentNode);
			});

			e.addEventListener('pointerenter', function (evt) {
				evt.target.parentNode
					.querySelectorAll('.gt_option img:not([src])')
					.forEach(function (img) {
						img.setAttribute('src', img.getAttribute('data-gt-lazy-src'));
					});
			});

			e.dispatchEvent(new PointerEvent('pointerenter', { bubbles: true, cancelable: true }));
		});

		const langLink = document.querySelector(`.gt_option a[data-gt-lang="${lang}"]`);

		if (langLink) {
			langLink.click();
			i18n.changeLanguage(lang);

			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lang)?.icon} size='lg' className='me-1' />
					<span>{`Language changed to ${getLangWithKey(lang)?.text}`}</span>
				</span>,
			);
		}
	};

	useEffect(() => {
		const storedTheme = localStorage.getItem('theme');
		if (storedTheme !== null) {
			setDarkModeStatus(storedTheme === 'dark');
		}
	}, [setDarkModeStatus]);

	useEffect(() => {
		localStorage.setItem('theme', darkModeStatus ? 'dark' : 'light');
		document.documentElement.setAttribute('theme', darkModeStatus ? 'dark' : 'light');
	}, [darkModeStatus]);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/login'), [navigate]);

	const [otpinfo, setOtpinfo] = useState({ email: '', otp: '' });

	const [isemptym, setIsemptym] = useState({ error: false, msg: t('*Required') });
	const [isemptyotp, setIsemptyotp] = useState({ error: false, msg: t('*Required') });

	const sendotp = async () => {
		if (isLoading) return;
		let cansendapi = true;
		const emailRegex = /^(?!\.)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
		// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!otpinfo.email) {
			setIsemptym({ error: true, msg: t('*Required') });
			cansendapi = false;
		} else if (emailRegex.test(otpinfo.email)) {
			setIsemptym({ error: false });
		} else {
			setIsemptym({
				error: true,
				msg: `${t('Enter an email address with a valid format.')}.`,
			});
			cansendapi = false;
		}

		if (cansendapi) {
			setIsLoading(true);
			const item = {
				action: 'forget_password',
				email: otpinfo.email,
			};

			try {
				const flechdata = await apiServicewt(item, navigate);

				if (flechdata.type === 'Success' || flechdata.type === 'success') {
					setUsernameInput(true);
					startmodel();
					setMsgLoadalert(flechdata.message);
					setMsgtypeLoadalert(flechdata.type);
				} else {
					startmodel();
					setMsgLoadalert(flechdata.message);
					setMsgtypeLoadalert(flechdata.type);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsLoading(false);
			}
		}
	};

	// resend OTP

	async function resendotp() {
		const item = {
			action: 'resend_otp',
			email: otpinfo.email,
		};
		const flechdata = await apiServicewt(item, navigate);

		if (flechdata.type === 'Success' || flechdata.type === 'success') {
			startmodel();
			setMsgLoadalert(flechdata.message);
			setMsgtypeLoadalert(flechdata.type);
		} else {
			startmodel();
			setMsgLoadalert(flechdata.message);
			setMsgtypeLoadalert(flechdata.type);
		}
	}

	// OTP Submit

	const verifyotp = async () => {
		if (isLoading) return;
		let cansendapi = true;
		// if (!otpinfo.otp) {
		// 	setIsemptyotp({ error: true, msg: t('*Required') });
		// 	cansendapi = false;
		// } else {
		// 	setIsemptyotp({ error: false });
		// }
		const otpRegex = /^\d{6}$/;
		if (!otpinfo.otp) {
			setIsemptyotp({ error: true, msg: t('*Required') });
			cansendapi = false;
		} else if (otpRegex.test(otpinfo.otp)) {
			setIsemptyotp({ error: false });
		} else {
			setIsemptyotp({ error: true, msg: t('Invalid OTP type') });
			cansendapi = false;
		}

		if (cansendapi) {
			setIsLoading(true);
			const item = {
				action: 'verify_otp',
				email: otpinfo.email,
				otp_code: otpinfo.otp,
			};

			try {
				const flechdata = await apiServicewt(item, navigate);

				if (flechdata.type === 'Success' || flechdata.type === 'success') {
					navigate('/landing/changepw', { state: flechdata });
					startmodel();
					setMsgLoadalert(flechdata.message);
					setMsgtypeLoadalert(flechdata.type);
				} else {
					startmodel();
					setMsgLoadalert(flechdata.message);
					setMsgtypeLoadalert(flechdata.type);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<Ralert
				msgloadalert={msgloadalert}
				msgtypeloadalert={msgtypeloadalert}
				loadalert={loadalert}
				closemodel={closemodel}
			/>
			<Rodal visible={isempty2} onClose={closemodel2}>
				<div
					style={{
						height: '100%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}>
					<div
						style={{
							//backgroundColor:"red",
							display: 'flex',
							paddingTop: '30px',
							width: '100%',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}>
						<Icon icon='ErrorOutline' color='danger' size='6x' />{' '}
						{/* Replace with your desired icon */}
						<span
							style={{
								color: 'black',
								fontSize: '15px',
								display: 'flex',
								width: '100%',
								alignItems: 'center',
								flexDirection: 'column',
								justifyContent: 'space-between',
								fontWeight: 'bold',
							}}>
							{t('Login Failed!')}
						</span>
					</div>
					<div
						style={{
							color: 'black',
							fontSize: '12px',
							display: 'flex',
							width: '100%',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}>
						{`${t('Please Recheck the Username and Password')}.`}
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							gap: '30px',
						}}>
						<Button
							style={{
								width: '100px',
								borderRadius: '7px',
								fontSize: '18px',
								backgroundColor: '#fd7e14',
								color: 'white',

								//color: 'primary',
								//outline: 'none',
								//border: '2px solid skyblue',
							}}
							color='danger'
							isLight
							onClick={closemodel2}>
							{t('Close')}
						</Button>
					</div>
				</div>
			</Rodal>
			<div className='bg-img'>
				<PageWrapper title={'Login'}>
					<div
						className='g-3'
						style={{
							position: 'absolute',
							top: 8,
							right: 8,
							display: 'flex',
							justifyContent: 'flex-end',
						}}>
						<div className='col-md-auto'>
							<div className='row g-3'>
								<div className='col-auto'>
									<Popovers trigger='hover' desc='Dark / Light mode'>
										<Button
											{...styledBtn}
											icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
											onClick={() => setDarkModeStatus((prev) => !prev)}
											aria-label='Toggle fullscreen'
											data-tour='dark-mode'
										/>
									</Popovers>
								</div>
								<div className='col-auto'>
									<Dropdown>
										<DropdownToggle hasIcon={false}>
											<Button
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...styledBtn}
												icon={getLangWithKey(i18n.language)?.icon}
												aria-label='Change language'
												data-tour='lang-selector'
											/>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
											{Object.keys(LANG).map((key) => (
												<DropdownItem key={LANG[key].lng}>
													<Button
														icon={LANG[key].icon}
														onClick={() =>
															changeLanguage(LANG[key].lng)
														}>
														{LANG[key].text}
													</Button>
												</DropdownItem>
											))}
										</DropdownMenu>
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
					<Page
						className='p-0 container-xxl'
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '100vh',
						}}>
						{/* <div className='row h-100 align-items-center justify-content-center'>
							<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
								<br />
								<br />
								<br /> */}
						<Card
							className='shadow-3d-dark'
							data-tour='login-page'
							style={{
								maxWidth: '400px',
								maxHeight: '545px',
							}}>
							<CardBody
								style={{
									// width: '600px',
									// height: '545px',
									//display: 'flex',
									justifyContent: 'center',
								}}>
								<div className='text-center my-3'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									<div className='col-12'>
										{!usernameInput ? (
											<>
												<FormGroup
													id='login-username'
													isFloating
													label={t('Your email')}>
													<Input
														type='email'
														onChange={(e) => {
															setOtpinfo((pre) => {
																const temp = {
																	...pre,
																	email: e.target.value,
																};
																return temp;
															});
														}}
													/>
												</FormGroup>
												{isemptym.error ? (
													<p
														style={{
															color: 'red',
															fontSize: '12px',
														}}>
														{isemptym.msg
															? isemptym.msg
															: t('*Required')}
													</p>
												) : (
													<br />
												)}
											</>
										) : (
											<>
												<FormGroup id='login-otp' isFloating label='OTP'>
													<Input
														type='text'
														value={otpinfo?.otp}
														onChange={(e) => {
															setOtpinfo((pre) => {
																const temp = {
																	...pre,
																	otp: e.target.value,
																};
																return temp;
															});
														}}
													/>
												</FormGroup>
												{isemptyotp.error ? (
													<p
														style={{
															color: 'red',
															fontSize: '12px',
														}}>
														{isemptyotp.msg
															? isemptyotp.msg
															: t('*Required')}
													</p>
												) : null}
											</>
										)}
									</div>
									<br />
									{usernameInput && (
										<div className='col-12'>
											<a
												//href='landing/forgot'
												style={{
													// color: 'white',
													marginLeft: '8px',
													fontSize: 13,
												}}>
												{t("Didn't get the code?")}
											</a>
											<a
												onClick={() => resendotp()}
												style={{ cursor: 'pointer' }}>
												&nbsp;&nbsp; {t('Resend')}
											</a>
										</div>
									)}

									<div className='col-12'>
										{!usernameInput ? (
											<Button
												color='success'
												className='w-100 py-3'
												onClick={sendotp}
												disabled={isLoading}>
												{t('Continue')}
											</Button>
										) : (
											<Button
												color='warning'
												className='w-100 py-3'
												onClick={() => verifyotp()}
												disabled={isLoading}>
												{t('Submit')}
											</Button>
										)}
									</div>
									<br />
									{/* {!usernameInput && (
												<div className='col-12'>
													<Button
														color='light'
														className='w-100 py-3'
														onClick={handleOnClick}
														// onClick={() => verifyotp()}
													>
														Go back
													</Button>
												</div>
											)} */}

									<div className='col-12'>
										{!usernameInput ? (
											<Button
												color='light'
												className='w-100 py-3'
												onClick={handleOnClick}
												// onClick={() => verifyotp()}
											>
												{t('Go back')}
											</Button>
										) : (
											<Button
												color='light'
												className='w-100 py-3'
												onClick={handleOnClick}
												// onClick={() => verifyotp()}
											>
												{t('Go back')}
											</Button>
										)}
									</div>
								</form>
							</CardBody>
						</Card>
						{/* </div>
						</div> */}
					</Page>
				</PageWrapper>
			</div>
		</>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
