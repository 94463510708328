import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import headers from '../../routes/headerRoutes';

const HeaderRoutes = () => {
	const [servicepageroute, setServicepageroute] = useState(''); // n

	useEffect(() => {
		let servicepage = location.pathname.split('/allservices');

		if (servicepage.length === 2) {
			setServicepageroute(servicepage[1]);
		}
	}, [location.pathname]);

	return (
		<Routes>
			{headers.map((page, index) => {
				if (index === 7) {
					const temp = { ...page };
					temp.path = temp.path + servicepageroute;

					return (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Route key={temp.path} {...temp} />
					);
				} else {
					return (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Route key={page.path} {...page} />
					);
				}
			})}
		</Routes>
	);
};

export default HeaderRoutes;
